import React, { useEffect, useState } from "react";
import axios from "axios";
import "./css/DashboardHome.css";
import { Link } from "react-router-dom";

const AdminInspection = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [isReportOpen, setIsReportOpen] = useState(false);
    const isLoggedIn = window.localStorage.getItem("loggedIn") === "true";
    const [inspections, setInspections] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState('');
    const usersPerPage = 30;

    const toggleSidebar = () => {
        setSidebarOpen(!sidebarOpen);
    };

    const toggleReport = () => {
        setIsReportOpen(!isReportOpen);
    };

    const fetchInspections = async () => {
        try {
            const token = localStorage.getItem("token");
            const response = await axios.get("https://jubilant-backend.netlysystems.com/inspections", {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            const sortedInspections = response.data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
            setInspections(sortedInspections);
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        fetchInspections();
    }, []);

    if (!isLoggedIn) {
        alert("Token Expired Login!! To see Content")
        window.location.href = "/";
        return null;
    }

    const logOut = () => {
        window.localStorage.clear();
        window.location.href = "/";
    };

    const filteredRequests = inspections.filter((inspection) =>
        inspection.supervisorName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        inspection.sno.toLowerCase().includes(searchTerm.toLowerCase()) ||
        inspection.clientName.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const totalPages = Math.ceil(filteredRequests.length / usersPerPage);
    const indexOfLastUser = Math.min(currentPage * usersPerPage, filteredRequests.length);
    const indexOfFirstUser = (currentPage - 1) * usersPerPage;

    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    const openImageTab = (images) => {
        const imageUrls = images.map(image => `https://jubilant-backend.netlysystems.com/${image}`);
        const newTab = window.open("", "_blank");
        newTab.document.write("<html><head><title>Inspection Images</title></head><body>");
        imageUrls.forEach(url => {
            newTab.document.write(`<img src="${url}" style="max-width: 100%; margin: 10px;" />`);
        });
        newTab.document.write("</body></html>");
        newTab.document.close();
    };

    return (
        <>
            {/* SIDEBAR */}
            <section id="sidebar" className={sidebarOpen ? "" : "hide"}>
                <Link to="/" className="brand">
                    <i className="fas fa-smile icon"></i>CRM
                </Link>
                <ul className="side-menu">
                    <li><Link to={'/'}><i className="fas fa-dashboard icon"></i>Dashboard</Link></li>
                    <li className="divider" data-text="main">
                        Main
                    </li>
                    <li>
                        <Link to={'/adminUser'}><i className="fas fa-user icon"></i>Users</Link></li>
                    <li><Link to={'/adminClient'}><i className="fas fa-users icon"></i>Clients</Link></li>
                    <li><Link to={'/adminMachine'}><i className="fas fa-server icon"></i>Machines</Link></li>
                    <li><Link to={'/adminRequestApprovals'}><i className="fas fa-thumbs-up icon"></i>Approvals</Link></li>
                    <li><Link to={'/adminRequest'}><i className="fas fa-ticket icon"></i> Requests</Link></li>
                    <li><Link to='/adminInspection' className="active"><i className="fas fa-street-view icon"></i>Inspection</Link></li>
                    <li><Link to={'/adminTransferMachine'}><i className="fas fa-truck icon"></i>Transfer</Link></li>
                    <li className="divider" data-text="Inventory">Inventory</li>
                    <li><Link to={'/adminInventory'}><i className="fas fa-store icon"></i>Inventory</Link></li>
                    <li><Link to={"/adminDeadStock"}><i className="fas fa-window-close icon"></i>Dead Stock</Link></li>
                    <li className="divider" data-text="Report" onClick={toggleReport}>
                        Report
                        <i className={`fas fa-chevron-${isReportOpen ? 'up' : 'down'} icon`}></i>
                    </li>
                    {isReportOpen && (
                        <>
                            <li><Link to={"/admin-machine-detail-report"}><i className="fas fa-bus icon"></i>Machine Detail Report</Link></li>
                            <li><Link to={"/admin-machine-consolidated-report"}><i className="fas fa-cogs icon"></i>Machine Consolidated Report</Link></li>
                        </>
                    )}
                </ul>
                <div className="ads">
                    <div className="wrapper">
                        <Link to="#" className="btn-upgrade" onClick={logOut}>
                            Logout
                        </Link>
                        <p>
                            See you <span>Later!</span> Enjoy & <span>Have Fun!</span>
                        </p>
                    </div>
                </div>
            </section>
            {/* SIDEBAR */}

            {/* NAVBAR */}
            <section id="content">
                <nav>
                    <i className="fas fa-bars toggle-sidebar" onClick={toggleSidebar}></i>
                </nav>

                <main>
                    <h4 className="title" style={{ marginLeft: "20px" }}>Hello Admin,</h4>
                    <ul className="breadcrumbs">
                        <li><Link to="/adminInspection">Inspection</Link></li>
                        <li className="divider">/</li>
                        <li><Link to="#" className="active">Dashboard</Link></li>
                    </ul>
                    <div className="users-page">
                        <div style={{ backgroundColor: "#007bff", display: "flex", alignItems: "center", justifyContent: "center", padding: "10px 0" }} className="search-bar-container">
                            <h1 style={{ color: "white", margin: 2 }}>Inspections :</h1>
                            <input
                                className="search-input"
                                type="text"
                                placeholder="Search by Supervisors Name, Machine SNo, Site Name..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                            />
                        </div>
                    </div>
                    {filteredRequests.length === 0 && <p>Supervisor doesn't exist</p>}
                    <table style={{ marginLeft: "15px", borderCollapse: 'collapse', width: '100%' }}>
                        <thead>
                            <tr>
                                <th style={{ width: '80px', backgroundColor: '#007bff', color: 'white', padding: '8px', border: '1px solid black' }}>S.No</th>
                                <th style={{ width: '350px', backgroundColor: '#007bff', color: 'white', padding: '8px', border: '1px solid black' }}>Site Name</th>
                                <th style={{ width: '150px', backgroundColor: '#007bff', color: 'white', padding: '8px', border: '1px solid black' }}>Supervisor Name</th>
                                <th style={{ width: '300px', backgroundColor: '#007bff', color: 'white', padding: '8px', border: '1px solid black' }}>Supervisor Email</th>
                                <th style={{ width: '180px', backgroundColor: '#007bff', color: 'white', padding: '8px', border: '1px solid black' }}>Machine Serial No.</th>
                                <th style={{ width: '108px', backgroundColor: '#007bff', color: 'white', padding: '8px', border: '1px solid black' }}>Images</th>
                            </tr>
                        </thead>
                        <tbody>
                            {filteredRequests.slice(indexOfFirstUser, indexOfLastUser).map((inspection, index) => (
                                <tr key={index} style={{ backgroundColor: '#F1F0F6', borderBottom: '1px solid black' }}>
                                    <td style={{ width: '80px', border: '1px solid black', padding: '8px', textAlign: 'center' }}>{(currentPage - 1) * usersPerPage + index + 1}</td>
                                    <td style={{ width: '350px', border: '1px solid black', padding: '8px' }}>{inspection.clientName}</td>
                                    <td style={{ width: '150px', border: '1px solid black', padding: '8px' }}>{inspection.supervisorName}</td>
                                    <td style={{ width: '300px', border: '1px solid black', padding: '8px' }}>{inspection.supervisorEmail}</td>
                                    <td style={{ width: '180px', border: '1px solid black', padding: '8px' }}>{inspection.sno}</td>
                                    <td style={{ width: '108px', border: '1px solid black', padding: '8px', textAlign: 'center' }}>
                                        <button onClick={() => openImageTab(inspection.images)} className="btn btn-primary">
                                            View
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <div className="d-flex flex-wrap justify-content-center align-items-center my-3">
                        <button
                            className="btn btn-primary mx-2"
                            onClick={() => paginate(1)}
                            disabled={currentPage === 1}
                        >
                            First Page
                        </button>
                        <button
                            className="btn btn-primary mx-2"
                            onClick={() => paginate(currentPage === 1 ? 1 : currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            Previous Page
                        </button>
                        <button
                            className="btn btn-primary mx-2"
                            onClick={() => paginate(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            Next Page
                        </button>
                        <button
                            className="btn btn-primary mx-2"
                            onClick={() => paginate(totalPages)}
                            disabled={currentPage === totalPages}
                        >
                            Last Page
                        </button>
                        <span className="mx-2">Page {currentPage} of {totalPages}</span>
                    </div>
                </main>
            </section >
            {/* NAVBAR */}
        </>
    );
};

export default AdminInspection;